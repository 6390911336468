.bottom {
    background-color: #fff;
    padding: 1rem 0;
}

.top {
    background-color: #fff;
    padding: 1rem 0;
}

.inputLabel {
    text-align: right;
    font-weight: bold;
    padding-top: 8px;
}

.arrayLabel {
    margin-bottom: 24px;
    text-align: right;
    font-weight: bold;
    padding-top: 4px;
}

.arrayWrapper {
    border: 1px #ccc solid;
    border-radius: 5px;
    margin: 0px;
    margin-top: 10px;
    padding: 10px;
}

.arrayWrapper:last-child {
    margin-bottom: 24px;
}

.selectedColumn {
    background-color: #eee;
}

.applyColumnMappingWarning,
.applyColumnMappingError,
.applyColumnMapping {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.applyColumnMappingError {
    color: #f00;
}
.applyColumnMappingWarning {
    color: rgb(255, 170, 0);
}
