.logo {
    display: flex;
    align-items: center;
    color: inherit;
}

.medium {
    height: 30px;
    font-size: 1rem;
}

.large {
    height: 50px;
    font-size: 1.7rem;
}

.logo:hover {
    color: inherit;
}

.image {
    height: 100%;
    margin-right: 5px;
    margin-bottom: -2px;
}
